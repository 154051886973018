import { useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoopServerCall, MetaTagHandler, ServerCallHandler, serverHtmlAttributes } from "../../library/client/serverCallHandler";
import NonLodable from "./nonLodable";
import NavBar from "./components/navbar";
import BeforeAfter from "./components/beforeAfter";
import StatsCard from "./components/statsCard";
import Services from "./components/services";
import Reviews from "./components/reviews";
import FAQ from "./components/faq";
import Contact from "./components/contact";
import Footer from "./components/footer";
import { analyticsCall } from "../helpers/analytics";


const LandingPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [aosInit, setAosInit] = useState(false)

    useEffect(() => {
        if (!aosInit) {
            AOS.init();
            setAosInit(true)
        }
    }, [location])
    return (
        <>
            <MetaTagHandler>
                {() => (
                    <>
                        <title> FlyBy Shine | Revolutionary Drone Powered Cleaning Services</title>
                        <meta name="description" content="A Revolutionary Drone Powered Cleaning Service that is Faster, Safer, more Efficient, and Eco-friendly. Flyby Shine offers top-quality residential & commercial drone cleaning services that are safe, thorough, and reliable." />
                        <meta name="keywords" content="Drone Cleaning, Drone cleaning services, residential drone cleaning, home drone cleaning, hygienic drone cleaning, expert drone cleaning services, safe drone cleaning, thorough drone cleaning, professional drone cleaning, reliable drone cleaning, top-quality drone cleaning, drone cleaning services, drone cleaning solutions, drone cleaning experts" />
                        <meta property="og:image" content="/assets/commercial.png" />
                    </>
                )}
            </MetaTagHandler>
            <NavBar />

            <div className="page-hero">
                <div className="hero-video">
                    <div className="hero-video-overlay"></div>
                    <video playsInline autoPlay muted loop poster="/assets/heroPreload.png">
                        <source src="/assets/herovideo.mp4" type="video/mp4" />
                    </video>

                </div>
                <div className="hero-text-wrapper">
                    <div className="hero-text" data-aos="fade-up" data-aos-delay="150">
                        <span>100% Satisfaction Guaranted</span>
                        <h1>Revolutionary Drone Powered <span>Cleaning </span> Services</h1>
                        <p>Faster, Safer, more Efficient, and Eco-friendly Solutions with Flyby Shine</p>
                        <div className="button-groups">
                            <Link onClick={
                                () => {
                                    analyticsCall({
                                        category: "Book a Time - Click",
                                        action: "Book a Time Landing Page",
                                    })
                                }
                            } to={"/contact"} className="button button-primary">
                                Book a Time
                            </Link>
                            <Link to="/commercial-industrial"
                                className="button">
                                Our Services
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <BeforeAfter />

            <StatsCard />

            <Services />

            <Reviews />

            <FAQ />

            <Contact />

            <Footer />

            {/* <MetaTagHandler >
                {() => (
                    <>
                        <title>Poodle</title>
                    </>
                )}
            </MetaTagHandler>  */}
            {/* <NonLodable /> */}

            {/* <h2><ServerCallHandler id={"getDogHusky"} pathway={'getDog'} data={{breed:'husky'}} fetchHandler={'/api'} overwrite={'getDogHusky.src'} /></h2> */}



        </>
    )
}

export default LandingPage